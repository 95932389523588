import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  Popover,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getRecruiter } from "../../../Crud/rth";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import { AppDispatch } from "../../../redux/store";
import { setFilterState } from "../../../redux/features/teamHeadFilterSlice";
// import { setRecruiterDdState } from "../../../redux/features/recruiter";

const RecruiterDropDown: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const recruiterLeadState = useAppSelector((state) => state.recruiterDd);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [recruiterName, setRecruiterName] = useState<string[]>(recruiterLeadState.recruiterName);
  const [recruiterId, setRecruiterId] = useState<string[]>(recruiterLeadState.recruiterId);
  const [recruiterLead, setRecruiterLead] = useState<any[]>([]);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  console.log('searchLoading', searchLoading)

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  console.log("recruiterLead",recruiterLead);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchRecruiters = useCallback(
    async (query: string, currentPage: number) => {
      if (!hasMore && currentPage > 0) return; // Prevent further API calls if there's no more data

      setLoading(true);
      try {
        const response = await getRecruiter(query, currentPage);
        const fetchedData = response.data.map((user: any) => ({
          value: user.userId,
          label: user.firstName,
        }));

        setRecruiterLead((prev) => [
          ...prev,
          ...fetchedData.filter(
            (newItem: any) => !prev.some((item) => item.value === newItem.value)
          ),
        ]);

        setHasMore(fetchedData.length === 10); // Assuming 10 items per page, set `hasMore` to false when no more data
      } catch (error) {
        console.error("Error fetching recruiters:", error);
      } finally {
        setLoading(false);
      }
    },
    [hasMore]
  );

  useEffect(() => {
    if (searchQuery.trim()) {
      setSearchLoading(true);
    } else {
      setSearchLoading(false);
    }

    const timeoutId = setTimeout(() => {            
      fetchRecruiters(searchQuery, page); // Fetch new data for the first page
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchQuery,fetchRecruiters,page]);

  useEffect(() => {
    dispatch(
      setFilterState({
        recruiter: recruiterId.length ? recruiterId : [],
      })
    );
  }, [recruiterId, dispatch]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight >=
        listboxNode.scrollHeight - 10 &&
      hasMore && // Only fetch if there's more data
      !loading // Prevent fetching while loading
    ) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchRecruiters(searchQuery, nextPage);
    }
  };

  const handleToggle = (name: string, id: string) => {
    const updatedRecruiterNames = recruiterName.includes(name)
      ? recruiterName.filter((lead) => lead !== name)
      : [...recruiterName, name];
    setRecruiterName(updatedRecruiterNames);

    const updatedRecruiterIds = recruiterId.includes(id)
      ? recruiterId.filter((lead) => lead !== id)
      : [...recruiterId, id];
    setRecruiterId(updatedRecruiterIds);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleOpen}
        sx={{
          width: "100%",
          justifyContent: "space-between",
          textTransform: "none",
          display: "flex",
          alignItems: "center",
          borderColor: "#B0B0B0",
          color: "#000",
          "&:hover": {
            borderColor: "#A0A0A0",
          },
        }}
      >
        {recruiterName.length > 0 ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {recruiterName.slice(0, 1).map((name) => (
              <Chip
                key={name}
                label={name}
                onDelete={() =>
                  handleToggle(
                    name,
                    recruiterLead.find((lead) => lead.label === name)?.value || ""
                  )
                }
                sx={{ fontSize: "10px", height: "20px" }}
              />
            ))}
            {recruiterName.length > 1 && (
              <Chip
                label={`+${recruiterName.length - 1}`}
                sx={{
                  fontSize: "10px",
                  height: "20px",
                  backgroundColor: "#f0f0f0",
                  color: "#8C8C8C",
                }}
              />
            )}
          </Box>
        ) : (
          <Typography sx={{ color: "grey" }}>Choose</Typography>
        )}
        <ArrowDropDownIcon sx={{ fontSize: 18, marginLeft: 1 }} />
      </Button>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          sx: {
            width: anchorEl ? anchorEl.offsetWidth : "auto",
            marginTop: "2px",
          },
        }}
      >
        <Box sx={{ padding: 1 }}>
          <TextField
            size="small"
            placeholder="Search..."
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ marginBottom: 2, "& .MuiInputBase-input": { fontSize: "12px" } }}
          />
          <Box
            onScroll={handleScroll}
            sx={{ maxHeight: "250px", overflowY: "auto" }}
          >
            {recruiterLead
              .filter((lead) =>
                lead.label.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((lead) => (
                <MenuItem
                  key={lead.value}
                  onClick={() => handleToggle(lead.label, lead.value)}
                  sx={{ fontSize: "10px", padding: "4px 6px", gap: "10px" }}
                >
                  <Checkbox
                    checked={recruiterName.includes(lead.label)}
                    size="small"
                    sx={{ padding: "2px", "& .MuiSvgIcon-root": { fontSize: "14px" } }}
                  />
                  <ListItemText primary={lead.label} sx={{ margin: 0, fontSize: "10px" }} />
                </MenuItem>
              ))}
            {loading && (
              <Box sx={{ textAlign: "center", padding: 1 }}>
                <CircularProgress size={20} />
              </Box>
            )}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default RecruiterDropDown;
