import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Popover,
    TextField,
    Checkbox,
    ListItemText,
    MenuItem,
    Typography,
    Chip,
  } from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getRthBusinessInputData } from '../../../Crud/rth';
import { useDispatch } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import { setBusinessId, setBusinessName } from '../../../redux/features/businessDdSlice';
import { setFilterState } from '../../../redux/features/teamHeadFilterSlice';

const BusinessDropDown:React.FC = () => {

      const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
      const [searchQuery, setSearchQuery] = useState("");
      const businessName = useSelector((state: RootState) => state.businessDd.businessName);
      const businessId = useSelector((state: RootState) => state.businessDd.businessId);
      const [business, setBusiness] = useState<any[]>([]);
      const [searchLoading, setSearchLoading] = useState<boolean>(false);
      const isOpen = Boolean(anchorEl);
      const dispatch = useDispatch();
console.log('searchLoading',searchLoading)
        const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
        };
      
        const handleClose = () => {
          setAnchorEl(null);
        };

          useEffect(() => {
            if (searchQuery.trim()) {
                setSearchLoading(true);
              } else {
                setSearchLoading(false);
              }
              const timeoutId = setTimeout(() => {
                getRthBusinessInputData()
              .then((res) => {
                const dataWithIndex = res.data.map((item: any) => ({                    
                  value: item.businessId,
                  label: item.businessName,
                }));
                console.log("boss",dataWithIndex);                
                setBusiness(dataWithIndex);
              })
              .catch((err) => console.error(err));
            }, 300);
        
            return () => {
              clearTimeout(timeoutId);
            };
          }, [searchQuery]);

          
            useEffect(() => {
              dispatch(
                setFilterState({
                  business: businessId.length ? businessId : [],
                })
              );
            }, [businessId,dispatch]);

          const handleToggle = (name: string, id: string) => {
            const updatedBusinessNames = businessName.includes(name)
              ? businessName.filter((item) => item !== name)
              : [...businessName, name];            
            dispatch(setBusinessName(updatedBusinessNames));
          
            const updatedBusinessIds = businessId.includes(id)
              ? businessId.filter((item) => item !== id)
              : [...businessId, id];    
            dispatch(setBusinessId(updatedBusinessIds));
          };

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleOpen}
        sx={{
          width: "100%",
          justifyContent: "space-between",
          textTransform: "none",
          display: "flex", // Ensure button content is spaced correctly
          alignItems: "center", // Align icon and text
          borderColor: "#B0B0B0", // Grey border color
          color: "#000", // Text color for the button
          "&:hover": {
            borderColor: "#A0A0A0", // Lighter grey on hover
          },
        }}
      >
        {businessName.length > 0 ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {businessName.slice(0, 1).map((name) => (
              <Chip
                key={name}
                label={name}
                onDelete={() =>
                  handleToggle(
                    name,
                    business.find((lead) => lead.label === name)?.value ||
                      ""
                  )
                }
                sx={{
                  fontSize: "10px",
                  height: "20px",
                }}
              />
            ))}
            {businessName.length > 1 && (
              <Chip
                label={`+${businessName.length - 1}`}
                sx={{
                  fontSize: "10px",
                  height: "20px",
                  backgroundColor: "#f0f0f0",
                  color: "#8C8C8C",
                }}
              />
            )}
          </Box>
        ) : (
          <Typography sx={{ color: "grey" }}>Choose</Typography>
        )}
        <ArrowDropDownIcon sx={{ fontSize: 18, marginLeft: 1 }} />
      </Button>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            width: anchorEl ? anchorEl.offsetWidth : "auto", // Match the button's width
            maxHeight: "300px",
            marginTop: "2px",
            overflowY: "auto", // Allow scrolling if content overflows
          },
        }}
      >
        <Box sx={{ padding: 1 }}>
          <TextField
            size="small"
            placeholder="Search..."
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              marginBottom: 2,
              "& .MuiInputBase-input": { fontSize: "12px" },
            }}
          />
          {business
            .filter((lead) =>
              lead.label.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map((lead) => (
              <MenuItem
                key={lead.value}
                onClick={() => handleToggle(lead.label, lead.value)}
                sx={{
                  fontSize: "10px",
                  padding: "4px 6px",
                  gap: "10px", // Gap between checkbox and label
                }}
              >
                <Checkbox
                  checked={businessName.includes(lead.label)}
                  size="small"
                  sx={{
                    padding: "2px",
                    "& .MuiSvgIcon-root": { fontSize: "14px" }, // Smaller checkbox icon
                  }}
                />
                <ListItemText
                  primary={lead.label}
                  sx={{
                    margin: 0,
                    fontSize: "10px", // Reduced text size
                  }}
                />
              </MenuItem>
            ))}
        </Box>
      </Popover>
    </Box>    
  )
}

export default BusinessDropDown